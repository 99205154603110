import { defineMessages } from 'react-intl';

export default defineMessages({
	questionnaireCta: {
		id: 'homepage.onboarding.questionnaireCta',
		defaultMessage: 'What can we help with today?',
		description: 'Cta for user to select why they want to use Calm',
	},
	sleepQualityButton: {
		id: 'homepage.onboarding.sleepQualityButton',
		defaultMessage: 'Improve sleep quality',
		description: 'Button label for improving sleep quality flow cta',
	},
	reduceStressButton: {
		id: 'homepage.onboarding.reduceStressButton',
		defaultMessage: 'Reduce stress or anxiety',
		description: 'Button label for improving reduce stress flow cta',
	},
	improveFocusButton: {
		id: 'homepage.onboarding.improveFocusButton',
		defaultMessage: 'Improve focus',
		description: 'Button label for improving focus stress flow cta',
	},
	selfImprovementButton: {
		id: 'homepage.onboarding.selfImprovementButton',
		defaultMessage: 'Self-improvement',
		description: 'Button label for self-improvement stress flow cta',
	},
	somethingElseButton: {
		id: 'homepage.onboarding.somethingElseButton',
		defaultMessage: 'Something else',
		description: 'Button label for something else stress flow cta',
	},
	bringCalmButton: {
		id: 'homepage.onboarding.bringCalmButton',
		defaultMessage: 'Bring Calm to my organization',
		description: 'Button label for bring calm stress flow cta',
	},
});
