import { Tile } from '@/components/ui/QuestionnaireTile/types';

import AnxietyIcon from 'icons/goal-anxiety.svg';
import LeafIcon from 'icons/goal-leaf.svg';
import SleepIcon from 'icons/goal-sleep.svg';
import SunIcon from 'icons/goal-sun.svg';
import ZenIcon from 'icons/goal-zen.svg';

import messages from './messages';

export const onboardingTiles: Tile[] = [
	{
		id: 'sleep_quality',
		title: messages.sleepQualityButton,
		Icon: SleepIcon,
	},
	{
		id: 'reduce_stress',
		title: messages.reduceStressButton,
		Icon: AnxietyIcon,
	},
	{
		id: 'improve_focus',
		title: messages.improveFocusButton,
		Icon: ZenIcon,
	},
	{
		id: 'self_improvement',
		title: messages.selfImprovementButton,
		Icon: LeafIcon,
	},
	{
		id: 'something_else',
		title: messages.somethingElseButton,
		Icon: SunIcon,
	},
];
